import React from "react"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { Row, Container, Col } from 'react-bootstrap/'
import KonzekFooterLogo from "../images/konzek-new-logo-white.png"
import YerliUretimLogo from "../images/yerli-uretim-beyaz.png"
import RetmesMesa from "../images/mesa.png"
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import retmeslogoWhite from "../images/retmes-white-logo.svg"
import footerLink from "../data/footerLinks.yaml"

class Footer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      UrlQuery: "",
    }
  }

  componentDidMount() {
    this.setState({ UrlQuery: window.location.pathname })
  }
  render() {
    const { UrlQuery } = this.state
    const data = footerLink

    return (<Row className='footer-section'>
      <Container className='footer-container'>
        <Row className='footer-first-row'>
          <Col xs className='footer-col'>
            <Link tp='/'> <img style={{ width: '11rem' }} src={retmeslogoWhite} alt="retmes logo" /></Link>
            <Row className='social-media-footer' >
              <Col className='footer-logos' xs>
                <a className='footer-link' href="https://linkedin.com/company/retmes" aria-label="Retmes Linkedin" title='Retmes Linkedin'> <FaLinkedin fontSize='20px' /></a>
                <a className='footer-link' href="https://twitter.com/retmescom" aria-label="Retmes Twitter" title='Retmes Twitter'> <FaTwitter fontSize='20px' /></a>
              </Col>
            </Row>
          </Col>
          <Col xs className='footer-col'>
            <Link className='footer-link' to='/moduller'><h3 className='footer-title'><FormattedMessage id={`${data.title}`} /></h3></Link>
            <ul>
              {data.col.map((data, index) => {
                return (
                  <li key={`content_item_${index}`} >
                    <Link className='footer-link' to='/moduller' aria-label="footer-link"><FormattedMessage id={`${data.link}`} /></Link>
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col xs className='footer-col'>
            <Link className='footer-link' to='/moduller'><h3 className='footer-title'><FormattedMessage id={`${data.titleCol2}`} /></h3></Link>
            <ul>
              {data.col2.map((data, index) => {
                return (
                  <li key={`content_item2_${index}`}>
                    <Link className='footer-link' to={`${data.linkURL}`} aria-label="footer-link">
                      <FormattedMessage id={`${data.link}`} />
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col xs className='footer-col'>
            <h3 className='footer-title'><FormattedMessage id="media" /></h3>
            <ul>
              <li>
                <a className='footer-link' href='/retmes-logo.zip' aria-label="footer-link"><FormattedMessage id="logos" /></a>
              </li>
            </ul>
          </Col>
          <Col xs className='footer-col'>
            <Link className='footer-link' to='/iletisim'><h3 className='footer-title'><FormattedMessage id="contact" /></h3></Link>
            <address>Zümrütevler Mh. Nazmi İlker Sk. No:3<br />
              <br />Maltepe/İstanbul
           </address>
            <a className='footer-link' href="mailto:info@retmes.com" aria-label='info@retmes.com'>info@retmes.com</a>
          </Col>
        </Row>
        <Row className=''>
          <Col className='footer-logos' xs>
            <br />
            <br />
            <img className='footer-logo-mesa' style={{ width: '120px', display: `${UrlQuery.includes("/en") === false ? 'unset' : 'none'}` }} src={YerliUretimLogo} alt=" yerli üretim" />
            <img className='footer-logo-mesa' style={{ width: '75px', marginLeft: '10px' }} src={RetmesMesa} alt="retmes-footer-mesa-logo" />
          </Col>

        </Row>
        <Row className=''>
          <Col className='footer-logos' xs>
            <a href="https://www.konzek.com"> <img className='footer-logo-konzek' src={KonzekFooterLogo} alt="konzek-footer-logo" /> </a> |  © {new Date().getFullYear()}
          </Col>
        </Row>
      </Container>
    </Row >
    );
  }
}

export default injectIntl(Footer)