import React from "react"
import { Link } from "gatsby"
import { Dropdown } from 'react-bootstrap/'
import { injectIntl, FormattedMessage, } from "gatsby-plugin-intl"
import { FaGlobe } from 'react-icons/fa';
class Lang extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      WinLoc: "",
    }
  }

  componentDidMount() {
    this.setState({ WinLoc: window.location.pathname })
  }

  render() {
    const { WinLoc } = this.state
    return (
      <Dropdown
        drop="down"
        className='langselector-dropdown'
        size="sm"
        title="Drop small">
        <Dropdown.Toggle className='language-dropdown' id="dropdown-basic" aria-label='langauge-button'>
          <FaGlobe fontSize='20px' />
        </Dropdown.Toggle>
        <Dropdown.Menu className='language-dropdown-show'>
          {/* <Dropdown.Item><Link to={`/${WinLoc.replace('/tr/', '/en/')}`} className='modules-link'><FormattedMessage id="change_languagetoenglish" /></Link></Dropdown.Item> */}
          <Dropdown.Item><Link to="/en" className='modules-link'><FormattedMessage id="change_languagetoenglish" /></Link></Dropdown.Item>
          <Dropdown.Item><Link to={`${WinLoc.replace('/en/', '/tr/')}`} className='modules-link'><FormattedMessage id="change_languagetoturkish" /></Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default injectIntl(Lang)