import React from "react"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import { Accordion, Card } from 'react-bootstrap/'
import LangSelector from "../components/lang-select"
import Offering from "../components/offering"
import { slide as Menu } from 'react-burger-menu'
import { FaLinkedin, FaTwitter, FaEnvelope, FaPhone, FaWhatsappSquare } from 'react-icons/fa';
import retmesMms from "../images/MMS.svg"
import retmesPms from "../images/PMS.svg"
import oeeHor from "../images/retmes-oee-hor.svg"
import mlqHor from "../images/retmes-mlq-hor.svg"
import aboutIcon from "../images/sidebar-images/about.svg"
import contactIcon from "../images/sidebar-images/contact.svg"
import modulesIcon from "../images/sidebar-images/modules.svg"
import solutionsIcon from "../images/sidebar-images/solutions.svg"
import homeIcon from "../images/sidebar-images/home_icon.svg"
import RetmesAiIcon from "../images/sidebar-images/retmes-ai-icon.svg"
import helpPoint from "../images/guide.svg"
import blogIcon from "../images/blog.svg"

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true
    };
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  showSettings(event) {
    event.preventDefault();

  }
  render() {

    const retmeslogoIcon = "https://res.cloudinary.com/konzek/image/upload/v1603710628/Retmes/logo/SVG/logo/retmes-logo-default.svg"

    return (
      <Menu right
        burgerBarClassName="bm-burger-bars-dark">
        <Accordion className='sidebar-accordion'>
          <Link to='/'><img className='retmes-sidebar-icon' src={retmeslogoIcon} alt="retmes sidebar icon" /></Link>
          <Card >
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <img className='sidebar-menu-icons' src={homeIcon} alt="retmes ana sayfa" /><Link className='modules-link' to='/'><FormattedMessage id="home" /></Link>
              </Accordion.Toggle>
            </Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <img className='sidebar-menu-icons' src={aboutIcon} alt="retmes kurumsal" /><FormattedMessage id="corporate" /><div className="cross"></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className='accordion-card-body'>
                <Link className='modules-link' to="/retmes-hakkinda"><h3><FormattedMessage id="about_retmes" /></h3></Link> <br />
                <Link className='modules-link' to="/nasil-calisiyoruz"><h3><FormattedMessage id="how_we_work" /></h3></Link> <br />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <img className='sidebar-menu-icons' src={solutionsIcon} alt="retmes kurumsal" /> <Link style={{ display: 'flex' }} className='modules-link' to='/cozumler'><FormattedMessage id="solutions" /></Link> <div className="cross"></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className='accordion-card-body' >
                <Link className='modules-link' to='/cozumler/uretim-izleme-sistemi'><h3 className='solutions-header'><FormattedMessage id="footer_5_h2" /><img src={retmesMms} alt="retmes mms üretim izleme sistemi" /></h3></Link> <br />
                <Link className='modules-link' to='/cozumler/enerji-yonetim-sistemi'><h3 className='solutions-header'><FormattedMessage id="footer_6_h2" /><img src={retmesPms} alt="retmes pms enerji yönetim sistemi" /></h3></Link> <br />
                <Link className='modules-link' to='/cozumler/toplam-ekipman-verimliligi'><h3 className='solutions-header'><FormattedMessage id="footer_7_h2" /><img src={oeeHor} alt="retmes oee toplam ekipan verimliliği" /></h3></Link> <br />
                <Link className='modules-link' to='/cozumler/yapay-zeka-destekli-uretim'><h3 className='solutions-header'><FormattedMessage id="footer_8_h2" /><img src={mlqHor} alt="retmes mlq manufacturing IQ" /></h3></Link> <br />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card >
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={helpPoint} alt="retmes guide" /><Link className='modules-link' to='/rehber'><FormattedMessage id="guide" /></Link>
            </Accordion.Toggle>
          </Card>
          <Card >
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={blogIcon} alt="retme blog" /><Link className='modules-link' to='/blog'><FormattedMessage id="blog" /></Link>
            </Accordion.Toggle>
          </Card>
          <Card >
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={modulesIcon} alt="retmes modüller" /><Link className='modules-link' to='/moduller'><FormattedMessage id="modules" /></Link>
            </Accordion.Toggle>
          </Card>
          {/* <Card >
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={RetmesAiIcon} alt="Retmes AI" /><Link className='modules-link' to='/ai'>Retmes AI
              <span className='badge-custom'>Yeni</span></Link>
            </Accordion.Toggle>
          </Card> */}
          {/* <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={demoIcon} alt="retmes demo icon" /> <Link className='modules-link' to='/demo' >Demo</Link>
            </Accordion.Toggle>
          </Card> */}
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <img className='sidebar-menu-icons' src={contactIcon} alt="retmes iletişim icon" /><Link className='modules-link' to='/iletisim'><FormattedMessage id="contact" /></Link>
            </Accordion.Toggle>
          </Card>
        </Accordion>
        <div className='header-contact-bar'>
          <a className='footer-link ' href="https://linkedin.com/company/retmes" aria-label='Retmes Linkedin' title='Retmes Linkedin'> <FaLinkedin fontSize='20px' /></a>
          <a className='footer-link ' href="https://twitter.com/retmescom" aria-label='Retmes Twitter' title='Retmes Twitter'> <FaTwitter fontSize='20px' /></a>
          <a className='modules-link' href='mailto:info@retmes.com'><FaEnvelope fontSize='20px' aria-label='info@retmes.com' /></a>
          <a className='footer-link ' href='https://api.whatsapp.com/send?phone=905525623130&amp;text=Merhaba,%20Retmes%20çözümleri%20hakkında%20bilgi%20almak%20istiyorum.' target="_blank" rel="noopener" aria-label='Retmes Contact'> <FaWhatsappSquare fontSize='20px' /></a>
        </div>
        <div className='header-contact-bar-2'>

          <a href='tel:+902163921642' aria-label='call-button'>
            <div className='retmes-call-2-button-bar' style={{ width: '14rem', color: 'white', marginTop: '2rem' }}>
              <FaPhone fontSize='20px' />
            </div>
          </a>

          {/* <Offering /> */}
          <LangSelector />
        </div>
      </Menu>
    );
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
