import React from "react"
import { Button } from 'react-bootstrap/'
import { injectIntl, Link , FormattedMessage} from "gatsby-plugin-intl"

class Offering extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    const { intl } = this.props
    return (
      <Link to='/teklif'>
        <Button
          variant="warning"
          className='offer-button'>
          <FormattedMessage id="offering" />
        </Button>
      </Link>
    );
  }
}

export default injectIntl(Offering)