
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import { Container } from 'react-bootstrap/'
import Footer from "./footer"
import "./layout.css"
import './bootstrap.css';
import './retmes.css';
import './hamburger.css';
import './accordion.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (<>
    {/* <div className='notify-badge'>
      <Container>
        <div className='notify-dot'></div>
        <Link to='/kobigel'>KOSGEB KOBİGEL desteği için son başvuru tarihi: <strong>17 Eylül 2020	&#8594;</strong> 
       </Link>
      </Container>
    </div> */}
    <Header siteTitle={data.site.siteMetadata.title} />
    <main>{children}</main>
    <Footer />
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
